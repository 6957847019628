<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Ajouter un chauffeur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, MazPhoneNumberInput },
  data() {
    return {
      title: "Ajouter un chauffeur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      sexe: ["M", "F", "O"],
      sexeSelect: "",
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "titre", sortable: true, label: "Titre" },
        { key: "date", sortable: true },
        { key: "adminId", sortable: true, label: "Publié par" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      fileProfil: null,
      filePermis: null,
      fileCni: null,
      nom: "",
      prenom: "",
      adresse: "",
      indicatifContact: "",
      contact: "",
      datenaissance: "",
      email: "",
      numeropiece: "",
      successShow: false,
    };
  },

  async mounted() {
    //this.init();
  },

  methods: {
    async init() {},
    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    async AddChauffeur() {
      const chauffeur = await apiRequest(
        "POST",
        "admin/entreprise/chauffeur",
        {
          nom: this.nom,
          prenom: this.prenom,
          genre: this.sexeSelect,
          datenaissance: this.datenaissance,
          ville: this.adresse,
          indicatifContact: "",
          contact: this.contact,
          email: this.email,
          numeropiece: this.numeropiece,
          profil: "",
          cni: "",
          permis: "",
        },
        false
      );
      if (chauffeur && chauffeur.data) {
        let chauf = chauffeur.data.chauffeur;
        if (
          this.addProfile((chauf.id).toString()) &&
          this.addCni((chauf.id).toString()) &&
          this.addPermis((chauf.id).toString())
        ) {
          this.successShow = true;
          router.push(`/chauffeur/list`);
        }
      } else {
        console.log("erreur");
      }
    },
    async addProfile(idChauffeur) {
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("idChauffeur", idChauffeur);
        formData.append("pic", this.fileProfil);
        const returnfile = await apiRequest(
          "POST",
          "admin/entreprise/chauffeur-profile",
          formData,
          true
        );
        if (returnfile && returnfile.data) {
          return true;
        }
      }
    },
    async addCni(idChauffeur) {
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idChauffeur", idChauffeur);
        formData.append("cni", this.fileCni);
        const returnfile = await apiRequest(
          "POST",
          "admin/entreprise/chauffeur-cni",
          formData,
          true
        );
        if (returnfile && returnfile.data) {
          return true;
        }
      }
    },
    async addPermis(idChauffeur) {
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idChauffeur", idChauffeur);
        formData.append("permis", this.filePermis);
        const returnfile = await apiRequest(
          "POST",
          "admin/entreprise/chauffeur-permis",
          formData,
          true
        );
        if (returnfile && returnfile.data) {
          return true;
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" @submit.prevent="formSubmit">
              <div class="mt-2">
                <div class="row">
                  <div class="col-md-6">
                    <label for="">Nom</label>
                    <input type="text" class="form-control" v-model="nom" />
                  </div>
                  <div class="col-md-6">
                    <label for="">Prénom</label>
                    <input type="text" class="form-control" v-model="prenom" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="">Ville de résidence</label>
                    <input type="text" class="form-control" v-model="adresse" />
                  </div>
                  <div class="col-md-6">
                    <label for="">Contact</label>
                    <MazPhoneNumberInput
                      :class="{
                        'is-invalid': submitform && $v.contact.$error,
                      }"
                      v-model="contact"
                      default-country-code="BJ"
                      :translations="{
                        countrySelectorLabel: 'Code pays',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de téléphone',
                        example: 'Exemple :',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="">Date de naissance</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="datenaissance"
                  />
                </div>
                <div class="col-md-6">
                  <label for="">Sexe</label>
                  <multiselect
                    v-model="sexeSelect"
                    :options="sexe"
                    :close-on-select="true"
                  ></multiselect>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="">Email</label>
                  <input type="email" class="form-control" v-model="email" />
                </div>
                <div class="col-md-6">
                  <label for="">Numéro de pièce</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="numeropiece"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <label for="">Photo de la pièce d'identité</label>
                  <input
                    type="file"
                    id="fileCni"
                    ref="fileCni"
                    v-on:change="handleFileCni"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label for="">Photo du permis</label>
                  <input
                    type="file"
                    id="filePermis"
                    ref="filePermis"
                    v-on:change="handleFilePermis"
                    class="form-control"
                    placeholder="Photo de permis"
                  />
                </div>
                <div class="col-md-6">
                  <label for="">Photo du chauffeur</label>
                  <input
                    type="file"
                    id="fileProfil"
                    ref="fileProfil"
                    v-on:change="handleFileProfil"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="float-right mt-4">
                <button class="btn btn-primary" @click="AddChauffeur">
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="successShow"
      title="Chauffeur ajouter avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="success" @click="successShow = false">OK</b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
